<template>
    <section id="getting-started">
        <div class="content-wrapper">
            <div class="info-block">
                <h5>Getting started with <span>unco</span> is simple</h5>
            </div>
            <div class="separation-line"/>
            <div class="preview-block">
                <div class="item">
                    <div class="image-wrapper">
                        <video loop muted playsinline>
                            <source :src="require(`@/assets/video/upload-your-concept.mp4`)" type="video/mp4">
                            <p>Your browser doesn't support HTML5 video. Here is a <a :href="require(`@/assets/video/upload-your-concept.mp4`)">link to the video</a> instead.</p>
                        </video>
                    </div>
                    <h5>Upload your <br> concept</h5>
                    <template v-if="$vuetify.breakpoint.smAndUp">
                        <div class="separation-line"/>
                        <div class="description">
                            <div class="number">1.</div>
                            <p>It doesn't matter if it's <br> a napkin sketch or a <br> blurry picture</p>
                        </div>
                    </template>
                </div>
                <div class="item">
                    <div class="image-wrapper">
                        <video loop muted playsinline>
                            <source :src="require(`@/assets/video/review-and-comment.mp4`)" type="video/mp4">
                            <p>Your browser doesn't support HTML5 video. Here is a <a :href="require(`@/assets/video/review-and-comment.mp4`)">link to the video</a> instead.</p>
                        </video>
                    </div>
                    <h5>Review and <br> comment</h5>
                    <template v-if="$vuetify.breakpoint.smAndUp">
                        <div class="separation-line"/>
                        <div class="description">
                            <div class="number">2.</div>
                            <p>Work alongside our <br> team until you’re <br> completely satisfied</p>
                        </div>
                    </template>
                </div>
                <div class="item">
                    <div class="image-wrapper">
                        <video loop muted playsinline>
                            <source :src="require(`@/assets/video/export-and-use.mp4`)" type="video/mp4">
                            <p>Your browser doesn't support HTML5 video. Here is a <a :href="require(`@/assets/video/export-and-use.mp4`)">link to the video</a> instead.</p>
                        </video>
                    </div>
                    <h5>Export <br> & use</h5>
                    <template v-if="$vuetify.breakpoint.smAndUp">
                        <div class="separation-line"/>
                        <div class="description">
                            <div class="number">3.</div>
                            <p>Getting high quality <br> digital media is easy</p>
                        </div>
                    </template>
                </div>
            </div>
            <!--                <button>Try it for Free</button>-->
        </div>
    </section>
</template>

<script>
    export default {
        name: "getting-started-block"
    }
</script>

<style scoped lang="scss" src="./style.scss"></style>
