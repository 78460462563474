var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"getting-started"}},[_c('div',{staticClass:"content-wrapper"},[_vm._m(0),_c('div',{staticClass:"separation-line"}),_c('div',{staticClass:"preview-block"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"image-wrapper"},[_c('video',{attrs:{"loop":"","muted":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":require(`@/assets/video/upload-your-concept.mp4`),"type":"video/mp4"}}),_c('p',[_vm._v("Your browser doesn't support HTML5 video. Here is a "),_c('a',{attrs:{"href":require(`@/assets/video/upload-your-concept.mp4`)}},[_vm._v("link to the video")]),_vm._v(" instead.")])])]),_vm._m(1),(_vm.$vuetify.breakpoint.smAndUp)?[_c('div',{staticClass:"separation-line"}),_vm._m(2)]:_vm._e()],2),_c('div',{staticClass:"item"},[_c('div',{staticClass:"image-wrapper"},[_c('video',{attrs:{"loop":"","muted":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":require(`@/assets/video/review-and-comment.mp4`),"type":"video/mp4"}}),_c('p',[_vm._v("Your browser doesn't support HTML5 video. Here is a "),_c('a',{attrs:{"href":require(`@/assets/video/review-and-comment.mp4`)}},[_vm._v("link to the video")]),_vm._v(" instead.")])])]),_vm._m(3),(_vm.$vuetify.breakpoint.smAndUp)?[_c('div',{staticClass:"separation-line"}),_vm._m(4)]:_vm._e()],2),_c('div',{staticClass:"item"},[_c('div',{staticClass:"image-wrapper"},[_c('video',{attrs:{"loop":"","muted":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":require(`@/assets/video/export-and-use.mp4`),"type":"video/mp4"}}),_c('p',[_vm._v("Your browser doesn't support HTML5 video. Here is a "),_c('a',{attrs:{"href":require(`@/assets/video/export-and-use.mp4`)}},[_vm._v("link to the video")]),_vm._v(" instead.")])])]),_vm._m(5),(_vm.$vuetify.breakpoint.smAndUp)?[_c('div',{staticClass:"separation-line"}),_vm._m(6)]:_vm._e()],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-block"},[_c('h5',[_vm._v("Getting started with "),_c('span',[_vm._v("unco")]),_vm._v(" is simple")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',[_vm._v("Upload your "),_c('br'),_vm._v(" concept")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description"},[_c('div',{staticClass:"number"},[_vm._v("1.")]),_c('p',[_vm._v("It doesn't matter if it's "),_c('br'),_vm._v(" a napkin sketch or a "),_c('br'),_vm._v(" blurry picture")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',[_vm._v("Review and "),_c('br'),_vm._v(" comment")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description"},[_c('div',{staticClass:"number"},[_vm._v("2.")]),_c('p',[_vm._v("Work alongside our "),_c('br'),_vm._v(" team until you’re "),_c('br'),_vm._v(" completely satisfied")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',[_vm._v("Export "),_c('br'),_vm._v(" & use")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description"},[_c('div',{staticClass:"number"},[_vm._v("3.")]),_c('p',[_vm._v("Getting high quality "),_c('br'),_vm._v(" digital media is easy")])])
}]

export { render, staticRenderFns }