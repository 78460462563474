<template>
    <div class="visuals-page">
        <section id="intro">
            <div v-if="$vuetify.breakpoint.smAndUp" class="content-wrapper">
                <div class="background home-row">
                    <div class="gradient"/>
                    <div class="left-circles">
                        <img
                            src="@/assets/VisualsPageImages/left-circles.svg">
                    </div>
                    <div class="right-circles">
                        <img
                            src="@/assets/VisualsPageImages/right-circles-png.png">
                    </div>
                </div>
                <div class="content">
                    <div class="info-block">
                        <h1>Who needs a photo studio?</h1>
                        <div class="separation-line"/>
                        <p>
                            Traditional photoshoots are time-consuming, flat, and expensive. 3D rendering allows for a
                            <strong>better multi-angled view</strong>, so you can really show off.
                        </p>
                        <!--                        <button>Experience it Now</button>-->
                    </div>
                    <div class="showcase">
                        <div class="plate">
                            <div class="left-part">
                                <div
                                    class="item"
                                    v-for="i in 8"
                                    :key="i">
                                    <img :src="require(`@/assets/VisualsPageImages/${i}.png`)">
                                </div>
                            </div>

                            <div class="centrall-item">
                                <img src="@/assets/VisualsPageImages/9.png">
                            </div>

                            <div class="right-part">
                                <div
                                    class="item"
                                    v-for="i in [10, 11, 12, 13]"
                                    :key="i">
                                    <img :src="require(`@/assets/VisualsPageImages/${i}.png`)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="$vuetify.breakpoint.xsOnly" class="mobile-wrapper">
                <div class="cards-container">
                    <div
                        class="card"
                        v-for="i of 5"
                        :key="i">
                        <img src="@/assets/VisualsPageImages/9.png">
                    </div>
                </div>
                <div class="info-block">
                    <h1>Who needs a photo studio?</h1>
                    <div class="separation-line"/>
                    <p>
                        Traditional photoshoots are time-consuming, flat, and expensive. 3D rendering allows for a
                        <strong>better multi-angled view</strong>, so you can really show off.
                    </p>
                    <!--                    <button>Experience it Now</button>-->
                </div>
            </div>
        </section>
        <section id="adapt" class="home-row">
            <div v-if="$vuetify.breakpoint.smAndUp" class="preview-block">
                <div class="preview">
                    <div class="preview-controls">
                        <a
                            v-for="tab in adaptTabs"
                            :key="tab.value"
                            role="button"
                            @click="changeTab('adaptTab', tab.value)"
                            :class="['text-uppercase', {'is-active': tab.value === adaptTab}]">
                            {{ tab.label }}
                        </a>
                    </div>
                    <div class="image-wrapper">
                        <video v-show="adaptTab === 1" muted playsinline ref="adaptTab_video_1">
                            <source :src="require(`@/assets/video/reusable.mp4`)" type="video/mp4">
                            <p>Your browser doesn't support HTML5 video. Here is a <a :href="require(`@/assets/video/reusable.mp4`)">link to the video</a> instead.</p>
                        </video>
                        <video v-show="adaptTab === 2" muted playsinline ref="adaptTab_video_2">
                            <source :src="require(`@/assets/video/editable.mp4`)" type="video/mp4">
                            <p>Your browser doesn't support HTML5 video. Here is a <a :href="require(`@/assets/video/editable.mp4`)">link to the video</a> instead.</p>
                        </video>
                    </div>
                </div>
            </div>
            <div class="info-block">
                <h5>Adapt the media to your needs</h5>
                <div class="separation-line"/>
                <div class="description">
                    <p>Use your rendering anywhere you'd like. Update your designs for future use. Switch it up by changing the scale.</p>
                </div>
            </div>
            <div v-if="$vuetify.breakpoint.xsOnly" class="mobile-wrapper">
                <div class="image-wrapper">
                    <div class="title">
                        1. Reusable
                    </div>
                    <video loop muted playsinline preload="auto">
                        <source :src="require(`@/assets/video/reusable.mp4`)" type="video/mp4">
                        <p>Your browser doesn't support HTML5 video. Here is a <a :href="require(`@/assets/video/reusable.mp4`)">link to the video</a> instead.</p>
                    </video>
                </div>
                <div class="image-wrapper">
                    <div class="title">
                        2. Editable
                    </div>
                    <video loop muted playsinline preload="auto">
                        <source :src="require(`@/assets/video/editable.mp4`)" type="video/mp4">
                        <p>Your browser doesn't support HTML5 video. Here is a <a :href="require(`@/assets/video/editable.mp4`)">link to the video</a> instead.</p>
                    </video>
                </div>
            </div>
        </section>
        <section id="c">
            <div class="info-block">
                <h5>Showcase every. <br> single. detail.</h5>
                <div class="separation-line"/>
                <p>3D rendering is the closest thing to holding jewelry in your hands. Unco is where clarity, flexibility, speed, and customization come together to create your product, completely.</p>

            <!--                <button>Try it for Free</button>-->
            </div>
            <div class="preview-block home-row">
                <div class="item">
                    <div class="image-wrapper">
                        <img src="@/assets/VisualsPageImages/engraving.png">
                    </div>
                    <div class="title">
                        Engraving
                    </div>
                    <p>
                        Put engraved lettering that can be integrated into a configurator for enhanced user experience
                    </p>
                </div>
                <div class="item">
                    <div class="image-wrapper">
                        <img src="@/assets/VisualsPageImages/closeups.png">
                    </div>
                    <div class="title">
                        Closeups
                    </div>
                    <p>
                        All the small detail matters when it comes to jewelry. Show case this with closeups
                    </p>
                </div>
                <div class="item">
                    <div class="image-wrapper">
                        <img src="@/assets/VisualsPageImages/mood-setting.png">
                    </div>
                    <div class="title">
                        Mood/Setting
                    </div>
                    <p>
                        Make your products more interesting by having them in your desired environment setting
                    </p>
                </div>
            </div>
        </section>
        <section id="use-cases">
            <div class="home-row content-wrapper">
                <div class="info-block">
                    <div class="title-wrapper">
                        <div>Use cases</div>
                    </div>
                    <div class="separation-line"/>
                    <div class="description">
                        <h5 class="header">
                            Simple product management,<br>
                            built to collaborate
                        </h5>
                        <p>
                            Use your photorealistic rendering on a wide variety of platforms with ease
                        </p>
                        <!--                        <button>-->
                        <!--                            Learn More-->
                        <!--                        </button>-->
                    </div>
                </div>
                <div class="preview home-row">
                    <div class="preview-controls">
                        <a
                            v-for="tab in useCaseTabs"
                            :key="tab.value"
                            role="button"
                            @click="changeTab('useCaseTab', tab.value)"
                            :class="['text-uppercase', {'is-active': tab.value === useCaseTab}]">
                            {{ tab.label }}
                        </a>
                    </div>
                    <div class="image-wrapper">
                        <video v-show="useCaseTab === 1" muted playsinline ref="useCaseTab_video_1">
                            <source :src="require(`@/assets/video/e-commerce.mp4`)" type="video/mp4">
                            <p>Your browser doesn't support HTML5 video. Here is a <a :href="require(`@/assets/video/e-commerce.mp4`)">link to the video</a> instead.</p>
                        </video>
                        <video v-show="useCaseTab === 2" muted playsinline ref="useCaseTab_video_2">
                            <source :src="require(`@/assets/video/marketing.mp4`)" type="video/mp4">
                            <p>Your browser doesn't support HTML5 video. Here is a <a :href="require(`@/assets/video/marketing.mp4`)">link to the video</a> instead.</p>
                        </video>
                        <video v-show="useCaseTab === 3" muted playsinline ref="useCaseTab_video_3">
                            <source :src="require(`@/assets/video/social-media.mp4`)" type="video/mp4">
                            <p>Your browser doesn't support HTML5 video. Here is a <a :href="require(`@/assets/video/social-media.mp4`)">link to the video</a> instead.</p>
                        </video>
                    </div>
                    <!--            <button v-if="deviceWidth() <= 767">-->
                    <!--                Learn More-->
                    <!--            </button>-->
                </div>
            </div>
            <div class="mobile-wrapper">
                <div class="info-block">
                    <h5>Showcase your product on any platform</h5>
                    <div class="separation-line"/>
                    <p>Take your digital product experiences to the next level—without the hassle. </p>
                </div>
                <div class="preview-block">
                    <div class="item">
                        <img src="@/assets/HomePageImages/main-block-product-showcase.svg">
                        <div class="item-title">
                            E-Commerce
                            <div class="separation-line"/>
                        </div>
                    </div>
                    <div class="item">
                        <img src="@/assets/HomePageImages/main-block-product-showcase-audivisual.svg">
                        <div class="item-title">
                            Audiovisual
                            <div class="separation-line"/>
                        </div>
                    </div>
                    <div class="item">
                        <img src="@/assets/HomePageImages/main-block-product-showcase-social-media.svg">
                        <div class="item-title">
                            Social Media
                            <div class="separation-line"/>
                        </div>
                    </div>
                    <div class="item">
                        <img src="@/assets/HomePageImages/main-block-product-showcase-marketing.svg">
                        <div class="item-title">
                            Marketing
                            <div class="separation-line"/>
                        </div>
                    </div>
                </div>
                <!--                <button>Learn More</button>-->
            </div>
        </section>
        <section id="trusted-by" class="d-none">
            <div class="info-block">
                <h5>Trusted by</h5>
                <div class="separation-line"/>
                <h6>Unco has worked with leading jewelry designers and companies from around the world.</h6>
            </div>
            <div class="preview-block">
                <div class="item d-flex justify-center align-center">
                    <img src="@/assets/VisualsPageImages/sdc.jpg" alt="SDC">
                </div>
                <div class="item d-flex justify-center align-center">
                    <img src="@/assets/VisualsPageImages/reeds.png" alt="REEDS">
                </div>
                <div class="item d-flex justify-center align-center">
                    <img src="@/assets/VisualsPageImages/vera-wang.png" alt="VeraWang">
                </div>
                <div class="item d-flex justify-center align-center">
                    <img src="@/assets/VisualsPageImages/silent-opus.svg" alt="SilentOpus">
                </div>
                <div class="item d-flex justify-center align-center">
                    <img src="@/assets/VisualsPageImages/taylor-you.svg" alt="Taylor&You">
                </div>
                <div class="item d-flex justify-center align-center">
                    <img src="@/assets/VisualsPageImages/logan-hollowell.svg" alt="Logan Hollowell">
                </div>
                <div class="item d-flex justify-center align-center">
                    <img src="@/assets/VisualsPageImages/roxy.jpg" alt="ROXY">
                </div>
                <div class="item d-flex justify-center align-center">
                    <img src="@/assets/VisualsPageImages/quince.png" alt="Quince">
                </div>
            </div>
        </section>

        <getting-started-block/>
    </div>
</template>

<script>
    import GettingStartedBlock from "@/components/HomePageComponents/GettingStartedBlock";

    export default {
        name: "page-visuals",
        components: {
            GettingStartedBlock
        },
        data: () => ({
            activeSection: 0,
            observer: null,
            adaptTabs: [
                { value: 1, label: "Reusable" },
                { value: 2, label: "Editable" }
            ],
            useCaseTabs: [
                { value: 1, label: "E-commerce" },
                { value: 2, label: "Social media" },
                { value: 3, label: "Marketing" }
            ],
            adaptTab: 1,
            useCaseTab: 1
        }),
        mounted() {
            this.observer = new IntersectionObserver(this.onElementObserved, {
                threshold: 1
            });

            document.querySelectorAll("video").forEach(lazyVideo => {
                this.observer.observe(lazyVideo);
            });
        },
        beforeDestroy() {
            this.observer.disconnect();
        },
        methods: {
            onElementObserved(entries) {
                entries.forEach(({ target, isIntersecting }) => {
                    if (!isIntersecting) {
                        return;
                    }

                    target.play();
                    this.observer.unobserve(target);
                });
            },
            changeTab(tabName, tabVal) {
                if (this[tabName] === tabVal) return;
                this[tabName] = tabVal;

                this.$nextTick(() => {
                    this.$refs[`${tabName}_video_${tabVal}`].play();
                })
            }
        }
    };
</script>

<style scoped lang="scss" src="./style.scss"></style>
